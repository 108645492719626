import { useMemo } from "react";

// Go ChatGPT - don't have to add a package!
const hexToRgb = (hex?: string) => {
  if (!hex) return null;

  // Remove the '#' character if it's present
  hex = hex.replace(/^#/, "");

  // Parse the hex string into integer RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
};

export interface ThemeSwitcherTheme {
  colors?: { primary?: string; secondary?: string; tertiary?: string };
}

export const ThemeSwitcher = ({
  theme = {},
  ...props
}: {
  theme?: ThemeSwitcherTheme;
} & React.HTMLProps<HTMLDivElement>) => {
  // Construct variables for the theme
  const primaryColor = hexToRgb(theme.colors?.primary);
  const secondaryColor = hexToRgb(theme.colors?.secondary);
  const tertiaryColor = hexToRgb(theme.colors?.tertiary);

  // since this component is often rendered at the root, we'll memoize
  const colorOverrides = useMemo(() => {
    return {
      "--color-brand-primary": primaryColor?.join(" "),
      "--color-brand-secondary": secondaryColor?.join(" "),
      "--color-brand-tertiary": tertiaryColor?.join(" "),
    } as React.CSSProperties;
  }, [primaryColor, secondaryColor, tertiaryColor]);

  return <div {...props} style={colorOverrides} />;
};
