import { Text } from "@apollosproject/canvas-ui-web";
import { ResultOf } from "@apollosproject/cluster-client";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { Glyph } from "./Glyph";
import { NavbarUserFragment, NavbarChurchFragment } from "./NavbarFragment";
import { ProfileShareMenu } from "./ProfileShareMenu";
import { UserMenu } from "./UserMenu";

const Logo = ({
  src,
  srcDark,
  alt,
}: {
  src?: string;
  srcDark?: string;
  alt?: string;
}) => (
  <div className="flex space-x-2 items-center">
    {src ? (
      <>
        <img src={src} alt={alt} className="h-6 w-auto dark:hidden" />
        <img src={srcDark} alt={alt} className="h-6 w-auto hidden dark:block" />
      </>
    ) : (
      <Text as="h3">{alt}</Text>
    )}

    <Link to={"/"} className="group flex space-x-1 items-center">
      <Text
        as="small"
        className="text-fg-tertiary dark:text-fg-tertiary-dark group-hover:text-fg-primary dark:group-hover:text-fg-primary-dark"
      >
        on
      </Text>
      <Glyph className="w-4 fill-fg-tertiary dark:fill-fg-tertiary-dark group-hover:fill-fg-primary dark:group-hover:fill-fg-primary-dark" />
    </Link>
  </div>
);

export const Navbar = ({
  currentChurch,
  currentUser,
  currentUserCanJoinChurch = false,
}: {
  currentChurch?: ResultOf<typeof NavbarChurchFragment>;
  currentUser?: ResultOf<typeof NavbarUserFragment>;
  currentUserCanJoinChurch?: boolean;
}) => {
  // Sticky Navbar that hides when scrolling down
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("down");
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    if (!window) return;
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > lastScrollY) {
        if (scrollDirection !== "down") {
          setScrollDirection("down");
          setScrollOffset(scrollY);
        }
      } else {
        if (scrollDirection !== "up") {
          setScrollDirection("up");
        }
      }
      lastScrollY = scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollDirection]);

  const logo = currentChurch?.wordmarkLightUrl ?? undefined;
  const logoDark = currentChurch?.wordmarkDarkUrl ?? undefined;
  const name = currentChurch?.name ?? undefined;

  return (
    <>
      <nav
        className={clsx(
          "bg-system-chrome dark:bg-system-chrome-dark shadow fixed w-full z-10",
          scrollDirection === "down" ? "absolute" : "fixed"
        )}
        style={{ top: scrollDirection === "down" ? scrollOffset : 0 }}
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between items-center">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <Logo src={logo} srcDark={logoDark} alt={name} />
              </div>
            </div>

            <div className="flex gap-x-2">
              <ProfileShareMenu
                currentUser={currentUser}
                currentChurch={currentChurch}
                currentUserCanJoinChurch={currentUserCanJoinChurch}
              />

              <UserMenu currentUser={currentUser} orgName={name} />
            </div>
          </div>
        </div>
      </nav>
      <div className="h-16" /> {/* spacer for fixed navbar */}
    </>
  );
};
