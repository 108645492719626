export const Glyph = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24L9.00231 0H13.9977L23 24H0ZM11.3631 5.8363L6.63941 19.4399H16.3728L11.6491 5.8363H11.3631Z"
    />
  </svg>
);
