import {
  Button,
  Dropdown,
  DropdownButton,
  DropdownMenu,
  Text,
} from "@apollosproject/canvas-ui-web";
import { ResultOf } from "@apollosproject/cluster-client";
import { useHref } from "@remix-run/react";
import { useState } from "react";

import { NavbarChurchFragment, NavbarUserFragment } from "./NavbarFragment";


export const ProfileShareMenu = ({
  currentChurch,
  currentUser,
  currentUserCanJoinChurch = false,
}: {
  currentChurch?: ResultOf<typeof NavbarChurchFragment>;
  currentUser?: ResultOf<typeof NavbarUserFragment>;
  currentUserCanJoinChurch?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const href = useHref("#");
  const redirectToParams = new URLSearchParams({ redirectTo: href });

  if (!currentUser || !currentUserCanJoinChurch) return null;

  return (
    <Dropdown>
      {/* ↖️ Div prevents a flex-related layout shift when Dialog opens vs a Fragment */}
      <DropdownButton small surface="solid" onClick={() => setIsOpen(!isOpen)}>
        Join {currentChurch?.name}
      </DropdownButton>

      <DropdownMenu className="z-50" anchor="bottom end">
        <div className="flex flex-col items-stretch justify-stretch p-6 gap-y-2 max-w-80">
          <div className="pb-2">
            <Text as="h3">Join {currentChurch?.name} on Apollos</Text>
            <Text as="p" fg="secondary">
              Connect and engage with others in this community.
            </Text>
          </div>

          <Button
            to={`/join/${currentChurch?.slug}?${redirectToParams}`}
            prefetch="intent"
            surface="solid"
          >
            Join {currentChurch?.name}
          </Button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
