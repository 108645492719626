import {
  Text,
  AvatarButton,
  Button,
  Dropdown,
  DropdownButton,
  DropdownHeading,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownSeparator,
} from "@apollosproject/canvas-ui-web";
import { ResultOf } from "@apollosproject/cluster-client";
import { SignOut } from "@phosphor-icons/react/dist/csr/SignOut";
import { User } from "@phosphor-icons/react/dist/csr/User";
import { useHref } from "@remix-run/react";

import { NavbarUserFragment } from "./NavbarFragment";

export const UserMenu = ({
  currentUser,
  orgName,
}: {
  currentUser?: ResultOf<typeof NavbarUserFragment>;
  orgName?: string;
}) => {
  const fullName = `${currentUser?.profile?.firstName || ""} ${
    currentUser?.profile?.lastName || ""
  }`;

  const initials = `${(currentUser?.profile?.firstName || "")?.charAt(0)}${(
    currentUser?.profile?.lastName || ""
  )?.charAt(0)}`;

  const href = useHref("#");
  const redirectToParams = new URLSearchParams({ redirectTo: href });

  return (
    <Dropdown>
      <>
        <DropdownButton
          as={AvatarButton}
          src={currentUser?.profile?.photo?.uri}
          initials={initials}
          className="size-8"
          avatarClassName="size-8"
        >
          {!currentUser?.profile ? (
            <User
              size={20}
              className="place-self-center text-fg-secondary dark:text-fg-secondary-dark"
            />
          ) : null}
        </DropdownButton>
        <DropdownMenu className="z-50" anchor="bottom end">
          {currentUser && currentUser.profile ? (
            <>
              <DropdownSection aira-label="Profile">
                <DropdownHeading>{fullName}</DropdownHeading>
              </DropdownSection>
              <DropdownSection aria-label="Profile Navigation">
                <DropdownItem
                  to={`/me/profile?${redirectToParams}`}
                  prefetch="intent"
                >
                  <User />
                  Edit Profile
                </DropdownItem>
              </DropdownSection>
              <DropdownSeparator />
              <DropdownSection aria-label="Logout">
                <DropdownItem
                  to={`/auth/logout?${redirectToParams}`}
                  prefetch="none"
                >
                  <SignOut />
                  Sign out
                </DropdownItem>
              </DropdownSection>
            </>
          ) : (
            <div className="flex flex-col items-stretch justify-stretch p-6 gap-y-2 max-w-80">
              <div className="pb-2">
                <Text as="h3">Get started on Apollos</Text>
                <Text as="p" fg="secondary">
                  {orgName
                    ? `Connect with the ${orgName} community.`
                    : `Find meaningful content & community.`}
                </Text>
              </div>

              <Button
                to={`/auth/signup?${redirectToParams}`}
                prefetch="intent"
                surface="solid"
              >
                Sign up
              </Button>

              <Button
                to={`/auth/login?${redirectToParams}`}
                prefetch="intent"
                surface="tinted"
              >
                Sign in
              </Button>
            </div>
          )}
        </DropdownMenu>
      </>
    </Dropdown>
  );
};
