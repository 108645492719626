import {
  ThemeSwitcher,
  ThemeSwitcherTheme,
  ChurchAccessTokenProvider,
} from "@apollosproject/canvas-ui-web";
import { graphql } from "@apollosproject/cluster-client";
import { MetaFunction, json } from "@remix-run/node";
import { type LoaderFunctionArgs } from "@remix-run/node";
import { Outlet, useLoaderData } from "@remix-run/react";
import { useEffect, useRef } from "react";

import { analytics } from "../../analytics";
import { query } from "../../cluster.server";
import { Navbar } from "../../components/Navbar";
import { isLoggedIn, getSession } from "../../session.server";

export const getCurrentChurch = graphql(/* GraphQL */ `
  query getCurrentChurch {
    currentChurch {
      theme
      appleBundleId
      androidPkgId
      appleTeamId
      ...NavbarChurchFragment
    }
  }
`);

export const getCurrentUserSharedProfile = graphql(/* GraphQL */ `
  query getCurrentUserSharedProfile($loggedIn: Boolean!, $slug: String!) {
    currentUser @include(if: $loggedIn) {
      ...NavbarUserFragment
      id
      originId
      originType
      profile {
        id
        firstName
        lastName
        nickName
        email
      }
      connectedChurchProfile(churchSlug: $slug) {
        id
      }
    }
  }
`);

// in memory cache for app store ids
export const appStoreIdCache = new Map<string, string>();

export const fetchAppStoreId = async (bundleId: string) => {
  // Check if the app store id has already been fetched
  if (appStoreIdCache.has(bundleId)) {
    return appStoreIdCache.get(bundleId);
  }

  // Call the iTunes Search API to look up the app by bundle identifier
  const lookupUrl = `https://itunes.apple.com/lookup?bundleId=${bundleId}`;
  const response = await fetch(lookupUrl);

  if (!response.ok) {
    return null;
  }

  const data = await response.json();

  // Ensure that the app exists
  if (data.resultCount === 0) {
    return null;
  }

  // Extract the trackId from the first result
  const trackId = data.results[0].trackId;

  // update the cache
  appStoreIdCache.set(bundleId, trackId);

  // Construct the App Store URL
  return trackId;
};

const getPlayStoreLink = (androidPkgId: string) =>
  `https://play.google.com/store/apps/details?id=${androidPkgId}`;

const getAppStoreLink = (trackId: string) =>
  `https://apps.apple.com/app/id${trackId}`;

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  if (!params.handle) throw new Error("No handle provided");

  const loggedIn = await isLoggedIn(request);
  const session = await getSession(request);
  const accessToken = session.get("accessToken") || null;

  const slug = params.handle.replace(/-/g, "_");

  const currentChurchPromise = query({
    query: getCurrentChurch,
    church: params.handle,
    request,
  });

  // TODO: combine into one graphql request.
  // Can't do that currently b/c this query has to be ran without a church header
  const currentUserPromise = query({
    query: getCurrentUserSharedProfile,
    variables: { loggedIn, slug },
    request,
  });

  const [currentChurchData, currentUserData] = await Promise.all([
    currentChurchPromise,
    currentUserPromise,
  ]);

  const theme = JSON.parse(
    currentChurchData.currentChurch?.theme ?? "{}"
  ) as ThemeSwitcherTheme;

  const appStoreId = currentChurchData?.currentChurch?.appleBundleId
    ? await fetchAppStoreId(currentChurchData.currentChurch.appleBundleId)
    : null;

  const appStoreUrl = appStoreId ? getAppStoreLink(appStoreId) : null;
  const playStoreUrl = currentChurchData?.currentChurch?.androidPkgId
    ? getPlayStoreLink(currentChurchData.currentChurch.androidPkgId)
    : null;

  return json({
    currentChurch: {
      ...currentChurchData.currentChurch,
      appStoreId,
      appStoreUrl,
      playStoreUrl,
    },
    accessToken,
    theme,
    currentUser: currentUserData.currentUser,
    connectedChurchProfile: currentUserData.currentUser?.connectedChurchProfile,
  });
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  if (!data) return [];
  const { currentChurch } = data;

  if (!currentChurch.appStoreId) return [];

  return [
    {
      name: "apple-itunes-app",
      content: `app-id=${currentChurch.appStoreId}, app-argument=${location.pathname}`,
    },
    {
      title: currentChurch?.name
        ? `${currentChurch.name} on Apollos`
        : "Apollos",
    },
  ];
};

export default function Handle() {
  const data = useLoaderData<typeof loader>();
  const lastProfileRef = useRef<string>();

  useEffect(() => {
    if (
      data.currentUser?.profile?.id &&
      lastProfileRef.current !== data.currentUser?.profile?.id
    ) {
      lastProfileRef.current = data.currentUser?.profile?.id;
      analytics.identify(data.currentUser?.profile?.id, {
        church: "global",
        email: data.currentUser.profile?.email,
        firstName: data.currentUser.profile?.firstName,
        lastName: data.currentUser.profile?.lastName,
        nickName: data.currentUser.profile?.nickName,
        userId: data.currentUser.profile?.id,
        originId: data.currentUser?.originId,
        originType: data.currentUser?.originType,
        apollosId: data.currentUser?.profile?.id,
      });
    }
  }, [
    data.currentUser?.profile?.id,
    data.currentUser?.profile?.email,
    data.currentUser?.profile?.firstName,
    data.currentUser?.profile?.lastName,
    data.currentUser?.profile?.nickName,
    data.currentUser?.originId,
    data.currentUser?.originType,
  ]);

  return (
    <ChurchAccessTokenProvider
      church={data.currentChurch?.slug || "default"}
      accessToken={data?.accessToken}
    >
      <ThemeSwitcher theme={data.theme}>
        <Navbar
          currentChurch={data.currentChurch ?? undefined}
          currentUser={data.currentUser ?? undefined}
          currentUserCanJoinChurch={!data.connectedChurchProfile}
        />
        <Outlet />
      </ThemeSwitcher>
    </ChurchAccessTokenProvider>
  );
}
