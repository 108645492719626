import { ReactNode, useCallback } from "react";

import { ChurchAccessTokenContext } from "./churchAccessTokenContext";

// Define the props interface for the provider
interface ChurchAccessTokenProviderProps {
  church: string;
  accessToken?: string;
  children: ReactNode;
}

const APP_REDIRECT_URL =
  typeof process !== "undefined" && process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://l.apollos.app";

// Create the provider component
export function ChurchAccessTokenProvider({
  church,
  accessToken,
  children,
}: ChurchAccessTokenProviderProps): JSX.Element {
  const getRedirectUrl = useCallback(
    (originalUrl: string) => {
      const params = new URLSearchParams({
        link: originalUrl,
        church,
      });

      if (accessToken) {
        params.append("token", `Bearer ${accessToken}`);
      }

      return `${APP_REDIRECT_URL}/r?${params.toString()}`;
    },
    [church, accessToken]
  );

  const handleClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLAnchorElement;
    if (target && target.tagName === "A" && target.href) {
      event.preventDefault();

      try {
        const redirectUrl = getRedirectUrl(target.href);

        window.location.href = redirectUrl;
      } catch (error) {
        console.error("Error during redirection:", error);

        // Fall back to the original URL
        window.location.href = target.href;
      }
    }
  };

  return (
    <ChurchAccessTokenContext.Provider value={{ church, accessToken }}>
      <div onClick={handleClick}>{children}</div>
    </ChurchAccessTokenContext.Provider>
  );
}
